import React from 'react';
import PropTypes from 'prop-types';
import {KiButton, KiButtonGroup, KiColumnSelectModal, KiFontIcon, KiInput, KiSelect} from 'components';
import styles from 'containers/fundingVehicle/settings/borrowingBase.theme.scss';
import {ADVANCE_RATE_MAX_FRACTIONAL_DIGITS} from 'containers/fundingVehicle/settings/borrowingBase';
const OPERAND_MAP = new Map([['add', '+'], ['subtract', '–'], ['multiply', '×'], ['divide', '÷']]);

export const ColumnTypeDropdown = props => {
	const opts = [{label: 'Asset', value: 'asset'}, {label: 'Debt', value: 'debt'}];
	if (props.allowConstant) opts.push({label: 'Constant', value: 'constant'});
	return (
		<KiSelect
			isOptionDisabled={opt => props.disableOptions.includes(opt.value)}
			className={styles.columnTypeSelector}
			defaultValue={opts[0]?.value}
			value={opts.filter(x => x.value === props.value)}
			onChange={props.onChange}
			options={opts}
			getOptionLabel={option => option.label}
			style={props.style || undefined}
		/>
	);
};

ColumnTypeDropdown.propTypes = {
	onChange: PropTypes.func.isRequired,
	disableOptions: PropTypes.array,
	allowConstant: PropTypes.bool,
	value: PropTypes.any,
	style: PropTypes.object,
};

ColumnTypeDropdown.defaultProps = {
	disableOptions: [],
	allowConstant: false,
};

const AdjustmentItemDisplay = props => {
	return (
		<span className={styles.formulaText} title={`${OPERAND_MAP.get(props.operand)} ${props.label}`}>
			<b>{OPERAND_MAP.get(props.operand)}&nbsp;</b>&nbsp;{props.label}
		</span>
	);
};

AdjustmentItemDisplay.propTypes = {
	operand: PropTypes.string,
	label: PropTypes.string,
};

AdjustmentItemDisplay.defaultProps = {
	operand: 'add',
	label: '',
};

export const FormulaDisplay = props => {
	const {allColumns, balance, advanceRate, advanceRateType, adjustments} = props;

	const balanceDisplayName = allColumns.find(cc => cc?._id === balance)?.displayName || 'Unselected';
	let advanceRateDisplayName = allColumns.find(cc => cc?._id === advanceRate)?.displayName || 'Unselected';
	if (advanceRateType === 'constant') {
		const formatted = new Intl.NumberFormat(navigator.language, {
			maximumFractionDigits: ADVANCE_RATE_MAX_FRACTIONAL_DIGITS + 2,
		}).format(advanceRate * 100);
		advanceRateDisplayName = parseFloat(advanceRate) ? `${formatted}%` : 'Unselected';
	}
	return (
		<div className={styles.formulaList}>
			<span className={styles.formulaText} title={`(${balanceDisplayName} × ${advanceRateDisplayName})`}>
				= ({balanceDisplayName}&nbsp;<b>×</b>&nbsp;{advanceRateDisplayName})
			</span>
			<span className={styles.formulaText}>
				<b>–</b>&nbsp;Breaches
			</span>
			<span className={styles.formulaText}>
				<b>–</b>&nbsp;Excess Without Duplication
			</span>
			{adjustments
				.filter(x => !!x.columnId && !!x.label)
				.map((adj, i) => (
					<AdjustmentItemDisplay key={`adjustment-item-${i}`} {...adj} allColumns={allColumns} />
				))}
		</div>
	);
};

FormulaDisplay.propTypes = {
	allColumns: PropTypes.array,
	balance: PropTypes.string,
	advanceRate: PropTypes.string,
	advanceRateType: PropTypes.oneOf(['asset', 'debt', 'constant']),
	adjustments: PropTypes.array,
};

FormulaDisplay.defaultProps = {
	allColumns: [],
	balance: null,
	advanceRate: null,
	adjustments: [],
};

export const AdjustmentInputItem = props => {
	const ensureLabel = (label = '') => {
		if (!label?.trim() && props.columnId) {
			const newLabelVal = props.allColumns.find(x => x._id === props.columnId)?.displayName || '';
			props.modifyAdjustment(props.index, {label: newLabelVal});
		} else if (label.trim().length !== label.length) {
			props.modifyAdjustment(props.index, {label: label.trim()});
		}
	};
	return (
		<div className={styles.adjustmentInputWrapper}>
			<div className={styles.adjustmentInputWrapperLeft}>
				<KiButtonGroup
					className={styles.adjustmentSelect}
					value={props.operand}
					onChange={operand => props.modifyAdjustment(props.index, {operand})}
				>
					<KiButton style={{minWidth: 'unset', flexBasis: 'auto'}} value={'add'}>
						+
					</KiButton>
					<KiButton style={{minWidth: 'unset', flexBasis: 'auto'}} value={'subtract'}>
						–
					</KiButton>
				</KiButtonGroup>
				<KiInput
					type="string"
					style={{flex: '1'}}
					error={props.label === ''}
					value={props.label}
					onBlur={e => ensureLabel(e.target.value)}
					onChange={val => props.modifyAdjustment(props.index, {label: val})}
					placeholder="Enter a label..."
				/>
			</div>
			<div className={styles.adjustmentInputWrapperRight}>
				<KiColumnSelectModal
					isButton
					placeholder="Select a Column..."
					buttonClassName={styles.columnSelectionButton}
					disabled={!props.columnType}
					columnList={props.columnType === 'asset' ? props.assetColumns : props.debtColumns}
					value={props.columnId}
					modalColumns={{showEntityType: props.columnType === 'debt', showEntityName: false}}
					onSelect={x => props.modifyAdjustment(props.index, {columnId: x?._id})}
				/>
				<ColumnTypeDropdown
					value={props.columnType}
					onChange={opt => {
						if (opt.value !== props.columnType) {
							props.modifyAdjustment(props.index, {label: '', columnId: null, columnType: opt.value});
						}
					}}
				/>
				<KiFontIcon value={'delete'} onClick={() => props.removeAdjustment(props.index)} />
			</div>
		</div>
	);
};

AdjustmentInputItem.propTypes = {
	index: PropTypes.number,
	label: PropTypes.string,
	operand: PropTypes.string,
	columnType: PropTypes.string,
	columnId: PropTypes.string,
	assetColumns: PropTypes.array,
	allColumns: PropTypes.array,
	debtColumns: PropTypes.array,
	modifyAdjustment: PropTypes.func.isRequired,
	removeAdjustment: PropTypes.func.isRequired,
};

AdjustmentInputItem.defaultProps = {
	index: 0,
	operand: '+',
	columnType: 'asset',
	columnId: null,
	assetColumns: [],
	allColumns: [],
	debtColumns: [],
};

export const Adjustments = props => {
	const {adjustments, assetColumns, debtColumns, setAdjustments, allColumns} = props;

	const addAdjustment = () =>
		setAdjustments([
			...props.adjustments,
			{
				operand: 'add',
				columnType: 'asset',
				label: '',
				columnId: null,
			},
		]);

	const removeAdjustment = index => setAdjustments(props.adjustments.filter((x, i) => i !== index));

	const modifyAdjustment = (index, changes = {}) => {
		// If the columnId is changed, update the label if it matches the old column's display name
		if (changes.columnId && changes.columnId !== adjustments[index].columnId) {
			const previousColumnDisplayName = allColumns.find(x => x._id === adjustments[index].columnId)?.displayName;
			if ([previousColumnDisplayName || ''].includes(adjustments[index].label)) {
				changes.label = allColumns.find(x => x._id === changes.columnId)?.displayName || '';
			}
		}
		setAdjustments([
			...adjustments.slice(0, index),
			Object.assign({}, adjustments[index], changes),
			...adjustments.slice(index + 1),
		]);
	};

	return (
		<div className={styles.adjustmentInputList}>
			{adjustments.map((adj, i) => (
				<AdjustmentInputItem
					key={`adjustment-${i}`}
					index={i}
					style={{flex: '1 1 20rem'}}
					columnType={adj.columnType}
					columnId={adj.columnId}
					label={adj.label}
					operand={adj.operand}
					assetColumns={assetColumns}
					debtColumns={debtColumns}
					allColumns={allColumns}
					modifyAdjustment={modifyAdjustment}
					removeAdjustment={removeAdjustment}
				/>
			))}
			<KiButton style={{alignSelf: 'flex-end'}} label="Add Adjustment" onClick={() => addAdjustment()} />
		</div>
	);
};

Adjustments.propTypes = {
	adjustments: PropTypes.array,
	assetColumns: PropTypes.array,
	debtColumns: PropTypes.array,
	allColumns: PropTypes.array,
	setAdjustments: PropTypes.func.isRequired,
};

Adjustments.defaultProps = {
	adjustments: [],
	allColumns: [],
	assetColumns: [],
	debtColumns: [],
};
