import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {columnServiceApi, borrowingBaseConfigApi} from 'api';
import {KiColumnSelectModal, KiButton, KiInput} from 'components';
import styles from './borrowingBase.theme.scss';
import {FormulaDisplay, ColumnTypeDropdown, Adjustments} from './borrowingBaseConfig/formComponents';
import cx from 'classnames';

export const ADVANCE_RATE_MAX_FRACTIONAL_DIGITS = 9;

export const BorrowingBaseSetup = () => {
	const {datasetId, fundingVehicleId} = useParams();
	const [allColumns, setAllColumns] = useState([]);
	const [debtColumns, setDebtColumns] = useState([]);
	const [assetColumns, setAssetColumns] = useState([]);
	const [advanceRate, setAdvanceRate] = useState(null);
	const [original, setOriginal] = useState(null);
	const [balance, setBalance] = useState(null);
	const [balanceType, setBalanceType] = useState('asset');
	const [advanceRateType, setAdvanceRateType] = useState('asset');
	const [adjustments, setAdjustments] = useState([]);

	if (!window.BORROWING_BASE_WOA_ENABLED) {
		return (
			<div>
				<p style={{fontWeight: 'bold', color: 'red'}}>
					Borrowing Base Advanced Configuration is not enabled for this environment.
				</p>
			</div>
		);
	}

	useEffect(
		() => {
			const fetchData = async datasetId => {
				if (!datasetId) return;
				const assetColResults = await columnServiceApi.getColumnsFromService(datasetId, {
					sources: {
						includeAssetColumns: true,
						includeAssetCalculations: true,
					},
					filters: {
						dataTypes: ['numeric'],
					},
				});
				const debtColResults = await columnServiceApi.getColumnsFromService(datasetId, {
					sources: {
						includeDebtCalculations: {
							entityTypes: ['fundingVehicle'],
						},
						includeDebtInputs: {
							entityTypes: ['fundingVehicle'],
						},
					},
					filters: {
						dataTypes: ['numeric'],
					},
				});
				const numericAssetCols = assetColResults.map(col => ({...col, _type: 'asset'}));
				const numericDebtCols = debtColResults.map(col => ({...col, _type: 'debt'}));
				setAssetColumns(numericAssetCols);
				setDebtColumns(numericDebtCols);
				setAllColumns([...numericAssetCols, ...numericDebtCols]);
			};
			fetchData(datasetId).catch(e => {
				// eslint-disable-next-line no-console
				console.error('Error fetching columns for borrowing base setup', e);
				setAssetColumns([]);
				setDebtColumns([]);
				setAllColumns([]);
			});
		},
		[datasetId]
	);

	const formIsValid = useMemo(
		() =>
			!!balance &&
			(advanceRateType === 'constant'
				? parseFloat(advanceRate) && parseFloat(advanceRate) <= 1
				: !!advanceRate) &&
			adjustments.every(adj => !!adj.columnId && !!adj.columnType),
		[adjustments, balance, advanceRate]
	);

	const hasChanges = useMemo(
		() =>
			!_.isEqual(_.omit(original, ['_id', 'datasetId', 'fundingVehicleId']), {
				balance,
				balanceType,
				advanceRate,
				advanceRateType,
				adjustments,
			}),
		[original, balance, balanceType, advanceRate, advanceRateType, adjustments]
	);

	const resetForm = () => {
		setBalance(original?.balance || null);
		setBalanceType(original?.balanceType || 'asset');
		setAdvanceRate(original?.advanceRate || null);
		setAdvanceRateType(original?.advanceRateType || 'asset');
		setAdjustments(original?.adjustments || []);
	};

	useEffect(
		() => {
			const fetchData = async (datasetId, fundingVehicleId) => {
				if (!datasetId || !fundingVehicleId) return;
				const config = await borrowingBaseConfigApi.fetchBorrowingBaseConfig(datasetId, fundingVehicleId);
				if (config) {
					setOriginal(config);
					setBalance(config.balance);
					setBalanceType(config.balanceType);
					setAdvanceRate(config.advanceRate);
					setAdvanceRateType(config.advanceRateType);
					setAdjustments(config.adjustments);
				}
			};
			fetchData(datasetId, fundingVehicleId).catch(() => {
				resetForm(true);
			});
		},
		[datasetId, fundingVehicleId]
	);

	const handleSave = async () => {
		const config = {
			datasetId,
			fundingVehicleId,
			balance,
			balanceType,
			advanceRate,
			advanceRateType,
			adjustments,
		};
		let result;
		if (!original?._id) {
			result = await borrowingBaseConfigApi.createBorrowingBaseConfig(datasetId, fundingVehicleId, config);
		} else {
			result = await borrowingBaseConfigApi.updateBorrowingBaseConfig(datasetId, fundingVehicleId, config);
		}
		setOriginal(result);
	};

	const handleAdvanceRateBlur = e => {
		setAdvanceRate(
			new Intl.NumberFormat(navigator.language, {
				maximumFractionDigits: ADVANCE_RATE_MAX_FRACTIONAL_DIGITS + 2,
			}).format((parseFloat(e?.target?.value) || 0) / 100)
		);
	};

	return (
		<div className={styles.root}>
			<div className={styles.form}>
				<div className={cx(styles.formTop, {[styles.formTopWithMargin]: !!adjustments.length})}>
					<div className={styles.formulaItemRow}>
						<div className={styles.formulaItemTitle}>Balance</div>
						<div className={styles.formulaItemDetails}>
							<KiColumnSelectModal
								isButton
								buttonClassName={styles.columnSelectionButton}
								placeholder="Select a Column..."
								columnList={balanceType === 'asset' ? assetColumns : debtColumns}
								value={balance}
								modalColumns={{
									showEntityType: balanceType === 'debt',
									showEntityName: false,
								}}
								onSelect={x => setBalance(x?._id || null)}
							/>
							<ColumnTypeDropdown
								value={balanceType}
								onChange={x => {
									setBalanceType(x?.value);
									if (x?.value !== balanceType) {
										setBalance(null);
									}
									if (x.value === 'debt' && advanceRateType === 'asset') {
										setAdvanceRateType('debt');
										setAdvanceRate(null);
									}
								}}
							/>
						</div>
					</div>
					<div className={styles.formulaItemRow}>
						<div className={styles.formulaItemTitle}>× Advance Rate</div>
						<div className={styles.formulaItemDetails}>
							{// BLD-23177 - if balanceType is debt, advanceRateType must be debt or constant
							advanceRateType === 'constant' ? (
								<KiInput
									className={styles.advanceRateInputConstant}
									onBlur={handleAdvanceRateBlur}
									value={new Intl.NumberFormat(navigator.language, {
										maximumFractionDigits: ADVANCE_RATE_MAX_FRACTIONAL_DIGITS + 2,
									}).format(advanceRate * 100)}
									isNumberMasked={true}
									sendReactEvent={true}
									error={
										(!parseFloat(advanceRate) || parseFloat(advanceRate) > 1) &&
										'Must be between 0 and 100'
									}
									maskConfig={{
										decimalLimit: ADVANCE_RATE_MAX_FRACTIONAL_DIGITS,
										suffix: '%',
										allowNegative: false,
										integerLimit: 3,
									}}
								/>
							) : (
								<KiColumnSelectModal
									isButton
									buttonClassName={styles.columnSelectionButton}
									label=" "
									placeholder="Select a Column..."
									columnList={advanceRateType === 'asset' ? assetColumns : debtColumns}
									modalColumns={{
										showEntityType: advanceRateType === 'debt',
										showEntityName: false,
									}}
									value={advanceRate}
									onSelect={x => setAdvanceRate(x?._id || null)}
								/>
							)}
							<ColumnTypeDropdown
								disableOptions={balanceType === 'debt' ? ['asset'] : []}
								allowConstant={true}
								onChange={x => {
									setAdvanceRateType(x.value);
									if (x.value !== advanceRateType) {
										setAdvanceRate(null);
									}
								}}
								value={advanceRateType}
							/>
						</div>
					</div>
					<div className={styles.formulaItemRow}>
						<div className={styles.formulaItemTitle}>– Breaches</div>
						<div className={styles.staticValue}>Determined on run</div>
					</div>
					<div className={styles.formulaItemRow}>
						<div className={styles.formulaItemTitle}>– Excess Without Duplication</div>
						<div className={styles.staticValue}>Determined on run</div>
					</div>
				</div>
				<hr />
				<div className={styles.formulaItemRow}>
					<Adjustments
						allColumns={allColumns}
						adjustments={adjustments}
						setAdjustments={setAdjustments}
						assetColumns={assetColumns}
						debtColumns={debtColumns}
					/>
				</div>
				{hasChanges && (
					<React.Fragment>
						<hr />
						<div className={styles.actionRow}>
							<KiButton onMouseUp={() => resetForm()}>Reset</KiButton>
							<KiButton
								label="Save"
								disabled={!formIsValid || !hasChanges}
								onMouseUp={handleSave}
								primary
							/>
						</div>
					</React.Fragment>
				)}
			</div>
			<div className={styles.preview}>
				<b>Borrowing Base</b>
				<FormulaDisplay
					allColumns={allColumns}
					balance={balance}
					advanceRateType={advanceRateType}
					advanceRate={advanceRate}
					adjustments={adjustments}
				/>
			</div>
		</div>
	);
};
